import "index.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

import mermaid from "mermaid"
document.addEventListener("DOMContentLoaded", () => {
	mermaid.initialize({ startOnLoad: true })
	mermaid.run({
		querySelector: "pre code.language-mermaid"
	})
})

console.info("Bridgetown is loaded!")
